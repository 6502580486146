import { SpotlightWaitlistConstants } from '../../Mixture/View/exportComponent';
const mixtureParticipationAPI = process.env.MIXTURE_CHAMPAIGN_PARTICIPATION;
const mixtureChannelId = process.env.MIXTURE_CHANNEL_ID;
const mixtureCampaignIdIO2 = process.env.MIXTURE_CHAMPAIGN_ID_IO2;
const mixtureLocale = process.env.MIXTURE_CAMPAIGN_LOCALE;
const mixtureTraitSource = process.env.MIXTURE_TRAIT_SOURCE;
const mixtureEmailIdOptin = process.env.MIXTURE_OPTIN_ID_EMAIL;

function handleOptinId( optinsData, mixtureOptinId ) {
  let optinId = mixtureOptinId;
  let optinObj = optinsData.filter(
    (item) => item.optIn.id === String(mixtureOptinId)
  );
  if (optinObj.length > 0) {
    optinId = optinObj?.[0]?.optIn?.id;
  }
  return optinId;
}

function handleOptinText(optinsData, mixtureOptinId) {
  let optinText = '';
  let optinObj = optinsData.filter(
    (item) => item.optIn.id === String(mixtureOptinId)
  );
  if (optinObj.length > 0) {
    optinText = optinObj?.[0]?.optIn?.text?.[0]?.value;
  }
  return optinText;
}

const createMixtureBody = ( email, optinsData, emailOptInStatus )  =>{
  const body = {};

  body.campaign = {
    "campaignId": Number(mixtureCampaignIdIO2),
    "locale": mixtureLocale,
  };

  body.consumer = {
    "email": email,
  };

  body.traits = [
    {
      "traitId": mixtureTraitSource,
      "value": [SpotlightWaitlistConstants.interstitalSourceIO2],
    },
  ];

  if (emailOptInStatus) {
    body.optIns = [
      {
        optinId: handleOptinId(optinsData, mixtureEmailIdOptin),
        optinText: handleOptinText(optinsData, mixtureEmailIdOptin),
      },
    ];
  }

  return body;
};

export const mixtureFormRegisterIO2 = async ({
  email,
  optinsData,
  emailOptInStatus,
} ) => {

  try {
    let body = createMixtureBody( email, optinsData, emailOptInStatus );
    const response = await fetch(mixtureParticipationAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'channelId': mixtureChannelId,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
