import {
    Block,
    CampaignIframeOverlayConstants,
    SpotlightWaitlistConstants
   } from './exportComponent';   

const ContentProperty = (entity) => {
    const block = new Block(entity);
    const title = block.getFieldValue(SpotlightWaitlistConstants.title);
    const body = block.getFieldValue(SpotlightWaitlistConstants.body);
    const ctaLabel = block.getFieldValue(SpotlightWaitlistConstants.callToActionLabel);
    const legalText = block.getFieldValue(SpotlightWaitlistConstants.legalText);
    const emailDisclaimer = block.getFieldValue(SpotlightWaitlistConstants.emailDisclaimer);
    const emailDisclaimerTop = block.getFieldValue(SpotlightWaitlistConstants.emailDisclaimerTop);
    const surveyDescription = block.getFieldValue(SpotlightWaitlistConstants.surveyDescription);
    const formLabels = block.getFieldValue(SpotlightWaitlistConstants.formLabels);
    const classNames = block.getFieldValue(SpotlightWaitlistConstants.classNames);
    const anchorId = block.getFieldValue(SpotlightWaitlistConstants.anchorId);
    const backgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.backgroundAsset);
    const mobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAsset);
    const screen2BackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.backgroundAssetScrondScreen);
    const screen2MobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAssetSecondScreen);
    const thankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouTitleLabel)?.fields?.text;
    const thankYouBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.thankYouBackgroundAsset);
    const thankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouDescriptionLabel)?.fields?.text;
    const closeModalLabel = block.getFieldValue(SpotlightWaitlistConstants.closeModalLabel)?.fields?.text;
    const thankYouCallToAction = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToAction);
    const thankYouCallToActionLink = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToActionLink);
    const thankYouCallToActionIconName = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToActionIconName);
    const numberOfErrorsLabel = block.getFieldValue(SpotlightWaitlistConstants.numberOfErrorsLabel);
    const modalLabelFirst = block.getFieldValue(SpotlightWaitlistConstants.modalLabelFirst);
    const modalLabelSecond = block.getFieldValue(SpotlightWaitlistConstants.modalLabelSecond);
    const alternateThankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.alternateThankYouTitleLabel)?.fields?.text;
    const alternateThankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.alternateThankYouDescriptionLabel)?.fields?.text;
    const callToPreOrderActionLabel = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLabel);
    const callToPreOrderActionLink = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLink);
    const spotlightiO10Campaign = block.getFieldValue(SpotlightWaitlistConstants.isiO10camapign)
    const emailExceedLimitLabel = block.getFieldValue(SpotlightWaitlistConstants.emailLimitExceedLabel)?.fields?.text;
    const invalidLocaleLabel = block.getFieldValue(SpotlightWaitlistConstants.invalidLocaleLabel)?.fields?.text;
    const registerFailedError = block.getFieldValue(SpotlightWaitlistConstants.registerFailedError)?.fields?.text;
    const serviceUnavailableLabel = block.getFieldValue(SpotlightWaitlistConstants.serviceUnavailableLabel)?.fields?.text;
    const countrySelectLabel = block.getFieldValue(SpotlightWaitlistConstants.countrySelectLabel);
    const countrySelectList = block.getFieldValue(SpotlightWaitlistConstants.countrySelectList);
    const countrySelectListDefaultOption = block.getFieldValue(SpotlightWaitlistConstants.countrySelectListDefaultOption);
    const countrySelectErrorMessage = block.getFieldValue(SpotlightWaitlistConstants.countrySelectErrorMessage);
    const countrySelectMention = block.getFieldValue(SpotlightWaitlistConstants.countrySelectMention);
    const addressTypeCode = block.getFieldValue(SpotlightWaitlistConstants.addressTypeCode);
    const isImplicitTCAgree = block.getFieldValue(SpotlightWaitlistConstants.implicitTCAgree) || false;
    const isInterstitialPopup = block.getFieldValue(SpotlightWaitlistConstants.isInterstitialPopup);
    const rawTopicList = block.getFieldValue(SpotlightWaitlistConstants.topicList);
    const backgroundColor = block.getFieldValue(SpotlightWaitlistConstants.backgroundColor);
    const expiryHours = block.getFieldValue(CampaignIframeOverlayConstants.expiryHours);
    const labelFirstStep = block.getFieldValue(CampaignIframeOverlayConstants.labelFirstStep);
    const labelSecondStep = block.getFieldValue(CampaignIframeOverlayConstants.labelSecondStep);
    const screen2SubmitCtaText = block.getFieldValue(CampaignIframeOverlayConstants.screen2SubmitCtaText);
        
    return {
        title,
        body,
        ctaLabel,
        legalText,
        emailDisclaimer,
        emailDisclaimerTop,
        surveyDescription,
        formLabels,
        classNames,
        anchorId,
        backgroundAsset,
        mobileBackgroundAsset,
        screen2BackgroundAsset,
        screen2MobileBackgroundAsset,
        thankYouTitleLabel,
        thankYouBackgroundAsset,
        thankYouDescriptionLabel,
        closeModalLabel,
        thankYouCallToAction,
        thankYouCallToActionLink,
        thankYouCallToActionIconName,
        numberOfErrorsLabel,
        modalLabelFirst,
        modalLabelSecond,
        alternateThankYouTitleLabel,
        alternateThankYouDescriptionLabel,
        callToPreOrderActionLabel,
        callToPreOrderActionLink,
        spotlightiO10Campaign,
        emailExceedLimitLabel,
        invalidLocaleLabel,
        registerFailedError,
        serviceUnavailableLabel,
        countrySelectLabel,
        countrySelectList,
        countrySelectListDefaultOption,
        countrySelectErrorMessage,
        countrySelectMention,
        addressTypeCode,
        isImplicitTCAgree,
        isInterstitialPopup,
        rawTopicList,
        backgroundColor,
        expiryHours,
        labelFirstStep,
        labelSecondStep,
        screen2SubmitCtaText
    }
}

export default ContentProperty;