import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import times from 'lodash/times';


export default function DotNav({ className, count, current, color, onClick }) {
    const colorClass = color ? `ob-dot-nav__item--${color}` : '';

    const handleClick = useCallback(
        (event) => {
            if (onClick) {
                const index = parseInt(event.currentTarget.getAttribute('data-index'), 10);
                onClick(index, event);
            }
        },
        [onClick]
    );

    return (
        <nav className={`ob-dot-nav ${className || ''}`}>
            <ul className='ob-dot-nav__list'>
                {times(count, i => (
                    <li key={i} className={`ob-dot-nav__item ${colorClass} ${(i === current ? 'ob-dot-nav__item--active' : '')}`}>
                        {onClick ? (
                            <button
                                className='event_button_click ob-dot-nav__button'
                                data-action-detail={i + 1}
                                type='button'
                                data-index={i}
                                aria-pressed={(i === current) ? 'true' : 'false'}
                                onClick={handleClick}
                            >
                                <span className='ob-dot-nav__dot'>{i + 1}</span>
                            </button>
                        ) : (
                            <span className='ob-dot-nav__button'>
                                <span className='ob-dot-nav__dot'>{i + 1}</span>
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

DotNav.propTypes = {
    className: PropTypes.string,
    count: PropTypes.number.isRequired,
    current: PropTypes.number,
    color: PropTypes.oneOf(['blue', 'white']),
    onClick: PropTypes.func,
};
