import React from 'react'
import PropTypes from 'prop-types'

const FormSelect = ({ dataArray, value, selectLabel, name, onChange, isHalfWidth, ariaLabel, hasInitialValue, disabled  }) => {
    const options = dataArray.map(el => <option
        key={el.id}
        value={el.id}>
        {el.value}
    </option>)

    /* eslint-disable jsx-a11y/no-onchange */
    return <div className="ob-form-select" data-is-half-width={isHalfWidth}>
        {selectLabel &&
            <label htmlFor={name} className="ob-input__label ob-form-select__label">{selectLabel}</label>
        }
        <div className="ob-form-select__select-ctn">
            <select className="ob-form-select__select" aria-label={ariaLabel} name={name} id={name} onChange={onChange} value={value ? value : ''}
            disabled={disabled}>
                {!hasInitialValue &&
                    <option value='' key={0} disabled={true}></option>
                }
                {options}
            </select>
        </div>
    </div>
}

FormSelect.defaultProps = {
    isHalfWidth: false,
    hasInitialValue: false
};

FormSelect.propTypes = {
    dataArray: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })).isRequired,
    isHalfWidth: PropTypes.bool,
    selectLabel: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    name: PropTypes.string,
    ariaLabel: PropTypes.string,
    hasInitialValue: PropTypes.bool
}

export default FormSelect
