import {customJsonstringify, formatDateToStringValue} from '../../../adapters/helpers/Utils';
import { WarrantyExtensionConstants } from '../../../adapters/helpers/Constants';

export const recoverProductsFromUserData = (profileData, productCategoryList, warrantyExtensionTime, gCSUserproductList) => {
    let products = [];

    if(gCSUserproductList && Array.isArray(gCSUserproductList)) {
        gCSUserproductList.forEach(gCSProduct => {
            // continue if product is not deleted
            if(!gCSProduct.isDelete) {
                productCategoryList.forEach(productCategory => {
                    const productOverviews = productCategory?.fields?.productOverviewList;
                    if (productOverviews) {
                        productOverviews.forEach(productOverview => {
                            productOverview.fields.productVariants.forEach(contentfulProduct => {
                                let contentfulGCSProductId = contentfulProduct?.fields?.gCSProductId;
                                if (contentfulProduct?.fields?.janrainProductId) {
                                    contentfulGCSProductId = contentfulProduct?.fields?.janrainProductId;
                                }

                                let gCSProductId = gCSProduct?.productId;
                                if (gCSProductId == contentfulGCSProductId) {
                                    let enhanceProductVariant = JSON.parse(customJsonstringify(contentfulProduct.fields));
                                    // Product identifier eanCode or sku. It seems that it's called ean code in GCS even if they are SKU
                                    enhanceProductVariant.productId = gCSProduct.eanCode;

                                    // Order unique identifier. Is not post anywhere, but gives us the hability to identity two different order with the same product.
                                    // We use this identifier for delete or make primary manipulation.
                                    enhanceProductVariant.id = gCSProduct.orderId;

                                    // unique database identifier in GCS database. Needed for product registration.
                                    enhanceProductVariant.gCSProductId = gCSProductId;

                                    enhanceProductVariant.imageUrl = gCSProduct.imageUrl;
                                    enhanceProductVariant.registrationDate = formatDateToStringValue(gCSProduct.registrationDate, 'YYYY-MM-DD', 'DD/MM/YYYY');

                                    let warrantyDateObject = new Date(gCSProduct.warrantyExtendedDate);
                                    let warrantyExpirationDate = new Date(warrantyDateObject.getFullYear(), warrantyDateObject.getMonth(), warrantyDateObject.getDate());
                                    enhanceProductVariant.purchaseDate = formatDateToStringValue(gCSProduct.purchaseDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
                                    enhanceProductVariant.traitDate = formatDateToStringValue(gCSProduct.purchaseDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
                                    enhanceProductVariant.warrantyExpirationDate = formatDateToStringValue(warrantyExpirationDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
                                    enhanceProductVariant.warrantyExpirationDateInText = warrantyExpirationDate.toLocaleString(process.env.JANRAIN_CAPTURE_LOCALE, {
                                        month: 'long',
                                        year: 'numeric'
                                    });
                                    enhanceProductVariant.category = productCategory?.fields?.name;
                                    enhanceProductVariant.timeStamp = new Date().toUTCString();

                                    // display name
                                    enhanceProductVariant.title = getProductVariantName(contentfulProduct);

                                    enhanceProductVariant.isPrimary = gCSProduct?.isPrimary;

                                    products.push(enhanceProductVariant);
                                }
                            })
                        })
                    }
                });
            }
        });
    }
    return products;
};

export const enhanceProducts = (gCSUserproductList) => {
    let products = [];

    if(gCSUserproductList && Array.isArray(gCSUserproductList)) {
        gCSUserproductList.forEach(gCSProduct => {
            // continue if product is not deleted
            if(!gCSProduct.isDelete) {
                let gCSProductId = gCSProduct?.productId;
                                
                let enhanceProductVariant = {};
                // Product identifier eanCode or sku. It seems that it's called ean code in GCS even if they are SKU
                enhanceProductVariant.productId = gCSProduct.productCode;
                enhanceProductVariant.orderProductId = gCSProduct.orderProductId;
                enhanceProductVariant.typeCode = gCSProduct.typeCode;

                // Order unique identifier. Is not post anywhere, but gives us the hability to identity two different order with the same product.
                // We use this identifier for delete or make primary manipulation.
                enhanceProductVariant.id = gCSProduct.orderId;

                // unique database identifier in GCS database. Needed for product registration.
                enhanceProductVariant.gCSProductId = gCSProductId;

                enhanceProductVariant.imageUrl = gCSProduct.productGasImageUrl;
                enhanceProductVariant.mainAsset = gCSProduct.productGasImageUrl;
                enhanceProductVariant.registrationDate = formatDateToStringValue(gCSProduct.registrationDate, 'YYYY-MM-DD', 'DD/MM/YYYY');

                let warrantyDateObject = new Date(gCSProduct.warrantyExtendedDate);
                let warrantyExpirationDate = new Date(warrantyDateObject.getFullYear(), warrantyDateObject.getMonth(), warrantyDateObject.getDate());
                
                enhanceProductVariant.purchaseDate = formatDateToStringValue(gCSProduct.purchaseDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
                enhanceProductVariant.traitDate = formatDateToStringValue(gCSProduct.purchaseDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
                enhanceProductVariant.warrantyExpirationDate = formatDateToStringValue(warrantyExpirationDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
                enhanceProductVariant.warrantyExpirationDateInText = warrantyExpirationDate.toLocaleString(process.env.JANRAIN_CAPTURE_LOCALE, {
                    month: 'long',
                    year: 'numeric'
                });
                enhanceProductVariant.timeStamp = new Date().toUTCString();

                // display name
                enhanceProductVariant.name = gCSProduct?.productName;
                enhanceProductVariant.title = gCSProduct?.productName;

                enhanceProductVariant.isPrimary = gCSProduct?.isPrimary;

                products.push(enhanceProductVariant);
        
            }
        });
    }
    return products;
};

export const removeProductFromJanrainList = (products, productIdToRemove, orderProductId) => {
    const orderIdWithProductId = `${productIdToRemove}${WarrantyExtensionConstants.janrinProductIdDelimiter}${orderProductId}`
    return products.filter(product => {
        if(product && product.productId && product.productId.toString().indexOf(WarrantyExtensionConstants.janrinProductIdDelimiter) > -1) {
            return product.productId !== orderIdWithProductId;
        } else {
            return product.productId !== productIdToRemove;
        }
    });
};

export const removeProductFromDisplayedList = (products, orderId, orderProductId) => {
    return products.filter(product => (product.id !== orderId || product.orderProductId !== orderProductId));
};

export const getJanrainProductId = (sku) => {
    let janrainId = sku;
    return janrainId;
};

export const getContenfulPrimaryProductCode = (product) => {
    let code = product?.productId + '_' + product?.purchaseDate;
    return code;
};

export const getProductVariantName = (productVariant) => {
    let title = productVariant?.fields?.name;
    if(productVariant?.fields?.shortTitle) {
        title = productVariant?.fields?.shortTitle;
    }
    return title;
};
